import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                root: {
                    height: '11px',
                    width: '11px',
                    margin: '10px'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff",
                    borderRadius:'4px',
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#878787',
                    transformOrigin: '0 0 !important',
                    '&.Mui-error': {
                        color: '#878787 !important'
                    },
                    '&.Mui-focused': {
                        color: '#878787 !important',
                        transform:'translate(14px, -20px) scale(0.75)',
                        
                    },
                    '&.MuiFormLabel-filled':{
                        color: '#878787 !important',
                        transform:'translate(14px, -20px) scale(0.75)',
                    }
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#d32f2f',
                    '&.Mui-error': {
                        color: '#d32f2f'
                    }
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: 'var(--gray)',
                    width: 760,
                },
            },
        },
        MuiBackdrop:{
            styleOverrides:{
                root:{
                    color: '#fff',
                    zIndex: 99
                }
            }
        },
        MuiIconButton:{
            styleOverrides:{
                root:{
                    color:'var(--blackColor)'
                }
            }
        },
        MuiSelect:{
            styleOverrides:{
                icon:{
                    color:'var(--blackColor) !important'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides:{
                    asterisk: {
                        '&.Mui-error': {
                          color: '#878787'
                        },
                      }
                }
          },
          MuiButton:{
            styleOverrides:{
                root:{
                    borderRadius: '4px',
                    height: '37px',
                    float: 'right',
                    marginTop: '30px',
                    background: 'var(--yellowColor)',
                    color:'var(--blackColor)',
                    padding: '10px 20px 10px 20px',
                    border: 'none',
                    cursor: 'pointer',
                    fontWeight: '600',
                    lineHeight: '17px',
                    textTransform:'none'
                }
            }
          }
    },
    typography: {
        fontFamily: '"Source Sans Pro", sans-serif !important',
        fontSize: 16,
        fontWeightRegular: 400,
        fontWeightBold: 700,
        h1: {
            fontSize: '3rem',
            fontWeight: 900,
            color: 'var(--yellowColor)',
            width: '100%'
        },
        h2: {
            fontSize: '1.4rem',
            fontWeight: 600,
        }
    },
    palette: {
        mode: "light",
        primary: {
            main: '#1976d2',
            light: '#42a5f5',
            dark: '#1565c0',
            contrastText: '#fff',
        },
        secondary: {
            main: '#E0C2FF',
            light: '#F5EBFF',
            contrastText: '#47008F',
        }
    },
});

export default theme;
