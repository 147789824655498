import { combineReducers } from "@reduxjs/toolkit";
import { Reducer } from "redux";
import { RootState } from "./store";
import { RESET_STATE_ACTION_TYPE } from "./reset-state";
import { APP_KEY, batchReducer } from "../common/slices/batch-slice";
import { TICKET_KEY, ticketReducer } from "../common/slices/ticket-slice";
import { ERROR_KEY, errorReducer } from "../common/slices/error-slice";
import { BATCH_DETAILS_KEY, batchDetailsReducer } from "../common/slices/batchDetails-slice";
import { TRAILER_KEY, trailersReducer } from "../common/slices/trailer-slice";
import { SITES_KEY, sitesReducer } from "../common/slices/sites-slice";
import { EDIT_BATCH_KEY, editBatchReducer } from "../common/slices/editBatch-slice";
import { BATCH_FLOW_KEY, batchFlowReducer } from "../common/slices/batchFlow-slice";

const reducers = {
  [APP_KEY]: batchReducer,
  [TICKET_KEY]: ticketReducer,
  [ERROR_KEY] : errorReducer,
  [BATCH_DETAILS_KEY] : batchDetailsReducer,
  [TRAILER_KEY]: trailersReducer,
  [SITES_KEY]: sitesReducer,
  [EDIT_BATCH_KEY] :editBatchReducer,
  [BATCH_FLOW_KEY]: batchFlowReducer
};

export const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};
