export const HEADER_NAME ="Batch";

export const field_name = {
    BATCH_NUMBER:'Batch ID',
    TRAILER_ID:"Trailer ID",
    CREATION_DATE:"Creation Date",
    CREATED_BY:"Created By",
    AMOUNT:"Amount",
    REMAINING:"Remaining",
    STATUS:'Status'
}

export const field_id = {
    batchId:'batchId',
    trailerId:"trailerId",
    creationDate:"creationDate",
    createdBy:"createdBy",
    amount:"amount",
    remainingMass:"remainingMass",
    status:'status',
    action:'action'
}

export const TRAILER_STATUS ={
    IN_ACTIVE : 'InActive'
}

export const CREATED_BY = {
    SYSTEM:'SYSTEM',
    USER:'USER'
}