import { Route, Routes, useNavigate } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import {
  BATCH_ROUTE,
  ERROR_ROUTE,
  HOME_ROUTE,
  OVERVIEW_ROUTE,
  TICKET_ROUTE,
} from "./utils/RoutesConstants";
import MainLayout from "./pages/MainLayout/MainLayout";
import { AuthorizedPage } from "./pages/AuthorizedPage/AuthorizedPage";
import Loading from "./components/loading/Loading";

const Home = lazy(() =>
  import(/* webpackChunkName: "home" */ "./pages/Home/Home")
);

const Overview = lazy(() =>
  import(/* webpackChunkName: "overview" */ "./pages/Overview/Overview")
);

const BatchList = lazy(() =>
  import(/* webpackChunkName: "batches" */ "./pages/Batches/BatchList")
);

const TicketList = lazy(() =>
  import(/* webpackChunkName: "ticket" */ "./pages/Ticket/TicketList")
);
const ErrorPage = lazy(() =>
  import(/* webpackChunkName: "errorpage" */ "./pages/generic-error-page/ErrorPage")
);
const App = () => {

  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.href;

    if (url.includes("state")) {
      navigate(HOME_ROUTE);
    }
  }, [navigate]);

  return (
    <Routes>
        <Route
          element={
            <AuthorizedPage>
              <MainLayout />
            </AuthorizedPage>
          }
        >
          <Route
            path={HOME_ROUTE}
            element={
              <Suspense>
                <Home />
              </Suspense>
            }
          />
          <Route
            path={OVERVIEW_ROUTE}
            element={
                <Suspense fallback={<Loading />}>
                  <Overview />
                </Suspense>
            }
          />
          <Route
            path={BATCH_ROUTE}
            element={
                <Suspense fallback={<Loading />}>
                  <BatchList />
                </Suspense>
            }
          />
          <Route
            path={TICKET_ROUTE}
            element={
                <Suspense fallback={<Loading />}>
                  <TicketList />
                </Suspense>
            }
          />
          <Route
            path={ERROR_ROUTE}
            element={
                <Suspense>
                  <ErrorPage />
                </Suspense>
            }
          />
        </Route>
      </Routes>
  );
};

export default App;
